@import "~normalize.css/normalize.css";
@import "~handorgel/lib/css/handorgel.min.css";
@import '~glightbox/dist/css/glightbox.min.css';

/* global settings */
@import './settings/_colors.scss'; @import './settings/_fonts.scss'; @import './settings/_images.scss'; @import './settings/_layout.scss';

/* functions and mixins */
@import './functions/_breakpoints.scss'; @import './functions/_grid.scss'; @import './functions/_mixins.scss'; @import './functions/_styles.scss';

/* base styles */
@import './base/_basic.scss'; @import './base/_fonts.scss'; @import './base/_typography.scss';

/* styles of various components */
@import './components/_blog-latest.scss'; @import './components/_cookiebar.scss'; @import './components/_downloads.scss'; @import './components/_edu-sites.scss'; @import './components/_floats.scss'; @import './components/_forms.scss'; @import './components/_glightbox.scss'; @import './components/_handorgel.scss'; @import './components/_header-text.scss'; @import './components/_iso-collection.scss'; @import './components/_layout.scss'; @import './components/_logo.scss'; @import './components/_magazin-iframe.scss'; @import './components/_menu-button.scss'; @import './components/_news-full.scss'; @import './components/_news-teaser.scss'; @import './components/_nig-styled.scss'; @import './components/_podcast.scss'; @import './components/_rocksolid_columns.scss'; @import './components/_schaugarten-full.scss'; @import './components/_schaugarten-teaser.scss'; @import './components/_swiper.scss'; @import './components/_tinymce.scss';

/* section styles */
@import './sections/_container.scss'; @import './sections/_footer.scss'; @import './sections/_header.scss'; @import './sections/_wrapper.scss';

/* module styles */
@import './modules/_mod_article.scss'; @import './modules/_mod_bp_products.scss'; @import './modules/_mod_bp_voucher_input.scss'; @import './modules/_mod_breadcrumb.scss'; @import './modules/_mod_customnav.scss'; @import './modules/_mod_iso_cart.scss'; @import './modules/_mod_iso_checkout.scss'; @import './modules/_mod_iso_orderdetails.scss'; @import './modules/_mod_iso_productlist.scss'; @import './modules/_mod_iso_productreader.scss'; @import './modules/_mod_navigation_main.scss'; @import './modules/_mod_newsfilter.scss'; @import './modules/_mod_newslist.scss'; @import './modules/_mod_newsmenu.scss'; @import './modules/_mod_search.scss';

/* element styles */
@import './elements/_ce_accordionSingle.scss'; @import './elements/_ce_downloads.scss'; @import './elements/_ce_form.scss'; @import './elements/_ce_gallery.scss'; @import './elements/_ce_general_teaser.scss'; @import './elements/_ce_headline.scss'; @import './elements/_ce_hyperlink.scss'; @import './elements/_ce_image.scss'; @import './elements/_ce_interactive_map.scss'; @import './elements/_ce_international_map.scss'; @import './elements/_ce_list.scss'; @import './elements/_ce_nig25_list.scss'; @import './elements/_ce_regionalstandorte.scss'; @import './elements/_ce_rsce_box_link.scss'; @import './elements/_ce_rsce_box_links.scss'; @import './elements/_ce_rsce_languages_start.scss'; @import './elements/_ce_rsce_magazin.scss'; @import './elements/_ce_rsce_news_filter.scss'; @import './elements/_ce_rsce_news_week_filter.scss'; @import './elements/_ce_rsce_newsletter.scss'; @import './elements/_ce_rsce_newsmap.scss'; @import './elements/_ce_rsce_sidebar.scss'; @import './elements/_ce_rsce_slider_image.scss'; @import './elements/_ce_rsce_team.scss'; @import './elements/_ce_survey.scss'; @import './elements/_ce_swiperStart.scss'; @import './elements/_ce_table.scss'; @import './elements/_ce_text.scss'; @import './elements/_ce_timeline.scss';
